import accessibleAutocomplete from 'accessible-autocomplete';

export function autocomplete(el, config = {}) {
  function init() {
    const id = config.id || el.getAttribute('data-autocomplete-id');
    const name = config.name || el.getAttribute('data-autocomplete-name');
    const source =
      config.source ||
      JSON.parse(el.getAttribute('data-autocomplete-source') ?? null);

    // autocomplete can't be initialized without source or id
    if (!source || !id) {
      return;
    }

    accessibleAutocomplete({
      element: el,
      id: id,
      defaultValue: config.defaultValue,
      name: name,
      source,
      autoselect: config.autoselect,
      confirmOnBlur: config.confirmOnBlur,
      cssNamespace: config.cssNamespace,
      displayMenu: config.displayMenu,
      minLength: config.minLength,
      onConfirm: config.onConfirm,
      placeholder: config.placeholder,
      required: config.required,
      showAllValues: config.showAllValues,
      showNoOptionsFound: config.showNoOptionsFound,
      tNoResults: () => config.tNoResults || 'Nenašla sa žiadna zhoda',
      tStatusNoResults: () => config.tStatusNoResults,
      tStatusQueryTooShort: (minQueryLength = config.minQueryLength) =>
        `Type in ${minQueryLength} or more characters for results`,
      tStatusSelectedOption: (
        selectedOption = config.selectedOption,
        length = config.length,
        index = config.index
      ) => `${selectedOption} ${index + 1} of ${length} is highlighted`,
      templates: config.templates
    });

    config.onInit?.();
  }

  function destroy() {
    el.innerHTML = '';
  }

  el._component_autocomplete = { init, destroy, config };

  return {
    init
  };
}

export function getInstance(el) {
  return el?._component_autocomplete ?? null;
}
